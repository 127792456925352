import {
  PERMISSIONS,
  NAVIGATION_GROUPS,
} from "components/AuthorizedRoute/utils/constants";
import { ROUTE_TITLES } from "./constants";

const routes = {
  home: {
    path: "/",
    getComponent: () => import("pages/HomePage"),
    title: "Home",
    exact: true,
  },
  catalog: {
    path: "/catalog",
    getComponent: () => import("pages/CatalogPage"),
    title: "Catalog",
    showInTableDropDown: process.env.REACT_APP_FEATURE_CATALOG !== "false",
    permission: PERMISSIONS.Catalog,
    assessments: true,
    group: NAVIGATION_GROUPS.Assessments.title,
    exact: true,
  },
  contactUs: {
    path: "/contact-us",
    getComponent: () => import("pages/ContactUsPage"),
    title: "Contact Us",
    group: NAVIGATION_GROUPS.About.title,
    exact: true,
  },
  myList: {
    path: "/my-list",
    getComponent: () => import("pages/MyListPage"),
    title: ROUTE_TITLES.THIRD_PARTIES,
    showInTableDropDown: process.env.REACT_APP_FEATURE_VENDORS !== "false",
    permission: PERMISSIONS.MyList,
    assessments: true,
    myList: true,
    exact: true,
  },
  myOrganization: {
    path: "/my-organization",
    getComponent: () => import("pages/OrganizationDetailsPage"),
    disabled: process.env.REACT_APP_FEATURE_ORGANIZATION_ROUTES !== "true",
    title: "Details",
    exact: true,
  },
  organizationPeople: {
    path: "/my-people",
    getComponent: () => import("pages/OrganizationPeoplePage"),
    disabled: process.env.REACT_APP_FEATURE_ORGANIZATION_ROUTES !== "true",
    title: "People",
    exact: true,
  },
  myListRequests: {
    path: "/my-list/requests",
    getComponent: () => import("pages/MyListPage/RequestsPage"),
    title: "Requests",
    permission: PERMISSIONS.MyList,
    assessments: true,
    myList: true,
    group: NAVIGATION_GROUPS.MyList.title,
    // disabled: process.env.REACT_APP_FEATURE_BOOK_OF_WORK !== "true",
    disabled: true,
    exact: true,
  },
  myListMyThirdParties: {
    path: "/my-list/third-parties",
    getComponent: () => import("pages/MyListPage/MyThirdPartiesPage"),
    title: ROUTE_TITLES.THIRD_PARTIES,
    permission: PERMISSIONS.MyList,
    assessments: true,
    myList: true,
    group: NAVIGATION_GROUPS.MyList.title,
    exact: true,
  },
  myListUnmatched: {
    path: "/my-list/unmatched",
    getComponent: () =>
      import("pages/MyListPage/UnmatchedPage/UnmatchedCustomerPage"),
    title: "Unmatched",
    permission: PERMISSIONS.MyList,
    assessments: true,
    disabled: process.env.REACT_APP_FEATURE_CUSTOMER_UNMATCHED_VIEW !== "true",
    myList: true,
    group: NAVIGATION_GROUPS.MyList.title,
    exact: true,
  },
  matchingHistoryCustomer: {
    path: "/my-list/unmatched/matching-history",
    getComponent: () =>
      import(
        "pages/ManageCustomerPage/MatchingHistoryPage/MatchingHistoryPage"
      ),
    title: "Matching History",
    permission: [PERMISSIONS.MyList],
    exact: true,
  },
  matchingHistoryAdmin: {
    path: "/manage-customers/:id/unmatched/matching-history",
    getComponent: () =>
      import(
        "pages/ManageCustomerPage/MatchingHistoryPage/MatchingHistoryPage"
      ),
    title: "Matching History",
    permission: PERMISSIONS.ManageCustomers,
    exact: true,
  },
  // myNetwork: {
  //   path: "/my-network",
  //   getComponent: () => import("pages/MyNetworkPage"),
  //   title: "My Network",
  //   permission: PERMISSIONS.MyNetwork.View,
  //   assessments: true,
  //   group: NAVIGATION_GROUPS.MyNetwork.title,
  //   showInTableDropDown: true,
  //   exact: true,
  // },
  // myNetworkListView: {
  //   id: "my-network-list-view",
  //   path: "/my-network/list-view",
  //   getComponent: () => import("pages/MyNetworkPage/ListViewPage/ListViewPage"),
  //   title: "List View",
  //   permission: PERMISSIONS.MyNetwork.View,
  //   group: NAVIGATION_GROUPS.MyNetwork.title,
  //   showInTableDropDown: true,
  //   exact: true,
  //   assessments: true,
  // },
  // myNetworkMapView: {
  //   id: "my-network-map-view",
  //   path: "/my-network/map-view",
  //   getComponent: () => import("pages/MyNetworkPage/MapViewPage/MapViewPage"),
  //   title: "Map View",
  //   permission: PERMISSIONS.MyNetwork.View,
  //   group: NAVIGATION_GROUPS.MyNetwork.title,
  //   showInTableDropDown: true,
  //   exact: true,
  //   assessments: true,
  // },
  purchased: {
    path: "/purchased",
    getComponent: () => import("pages/PurchasedPage"),
    title: "Purchased",
    permission: PERMISSIONS.Purchased.View.Full,
    assessments: true,
    showInTableDropDown: true,
    group: NAVIGATION_GROUPS.Purchased.title,
    exact: true,
  },
  templates: {
    path: "/templates-and-tags",
    getComponent: () => import("pages/TemplatesAndMappingPage"),
    title: ROUTE_TITLES.TEMPLATES,
    permission: [
      PERMISSIONS.CustomTemplate.View.MyOrganization,
      PERMISSIONS.CustomTemplate.Edit.MyOrganization,
      PERMISSIONS.TagsCollection.View.MyOrganization,
      PERMISSIONS.TagsCollection.Edit.MyOrganization,
    ],
    group: NAVIGATION_GROUPS.TemplatesAndMapping.title,
    exact: false,
    disabled: process.env.REACT_APP_FEATURE_CUSTOM_TEMPLATES !== "true",
    childRoutes: [
      {
        id: "tags-and-collections",
        path: "/templates-and-tags/tag-collections",
        getComponent: () =>
          import("pages/TemplatesAndMappingPage/TagsAndCollections"),
        title: ROUTE_TITLES.TAG_COLLECTION,
        permission: [
          PERMISSIONS.TagsCollection.View.MyOrganization,
          PERMISSIONS.TagsCollection.Edit.MyOrganization,
        ],
      },
      {
        id: "custom-templates",
        path: "/templates-and-tags/custom-templates",
        getComponent: () =>
          import("pages/TemplatesAndMappingPage/CustomTemplates"),
        title: ROUTE_TITLES.CUSTOM_TEMPLATES,
        permission: [
          PERMISSIONS.CustomTemplate.View.MyOrganization,
          PERMISSIONS.CustomTemplate.Edit.MyOrganization,
        ],
      },
    ],
  },
  thirdParties: {
    path: "/third-parties",
    getComponent: () => import("pages/ThirdPartiesPage"),
    title: ROUTE_TITLES.THIRD_PARTIES,
    permission: [
      PERMISSIONS.ThirdParty.Full,
      PERMISSIONS.ThirdParty.MyOrganization,
      PERMISSIONS.ThirdParty.Assigned,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
    assessments: true,
    group: NAVIGATION_GROUPS.ThirdParties.title,
    showInTableDropDown: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP !== "true",
    exact: true,
  },
  thirdPartyAssessments: {
    path: "/assessments",
    getComponent: () => import("pages/ThirdPartyAssessmentsPage"),
    title: "Assessments",
    permission: [
      PERMISSIONS.Assessment.Full,
      PERMISSIONS.Assessment.MyOrganization,
      PERMISSIONS.Assessment.Assigned,
      PERMISSIONS.Assessment.Stage10.Assigned,
      PERMISSIONS.Assessment.Stage10.Full,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
    assessments: true,
    group: NAVIGATION_GROUPS.ThirdParties.title,
    showInTableDropDown: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP !== "true",
    exact: true,
  },
  sitemap: {
    path: "/site-map",
    getComponent: () => import("pages/Sitemap"),
    title: "Site Map",
    exact: true,
  },
  login: {
    path: "/login",
    getComponent: () => import("pages/LoginPage"),
    title: "Login",
    allowAnonymous: true,
    exact: true,
  },
  implicitCallback: {
    path: "/implicit/callback",
    getComponent: () => import("pages/LoginCallback"),
    allowAnonymous: true,
    exact: true,
  },
  trusightUsers: {
    path: "/internal-users",
    getComponent: () => import("pages/TruSightUsersPage"),
    title: "Team & Permissions",
    permission: PERMISSIONS.ManageInternalUsers,
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
    group: NAVIGATION_GROUPS.Administrative.title,
    exact: true,
  },
  settings: {
    path: "/settings",
    getComponent: () => import("pages/SettingsPage"),
    title: ROUTE_TITLES.SETTINGS,
    permission: PERMISSIONS.SettingsAndIntegrations.View,
    disabled: process.env.REACT_APP_FEATURE_CONSUMPTION_API !== "true",
    assessments: true,
    group: NAVIGATION_GROUPS.SettingsAndIntegrations.title,
    showInTableDropDown:
      process.env.REACT_APP_FEATURE_CONSUMPTION_API !== "false",
    exact: true,
  },
  developerGuide: {
    path: "/developer-guide",
    getComponent: () => import("pages/DeveloperGuidePage"),
    title: ROUTE_TITLES.DEVELOPER_GUIDE,
    permission: PERMISSIONS.DeveloperGuide.View,
    disabled: process.env.REACT_APP_FEATURE_CONSUMPTION_API !== "true",
    group: NAVIGATION_GROUPS.SettingsAndIntegrations.title,
    showInTableDropDown:
      process.env.REACT_APP_FEATURE_CONSUMPTION_API !== "false",
    exact: true,
  },
  manageCustomers: {
    path: "/manage-customers",
    getComponent: () => import("pages/ManageCustomersPage"),
    title: ROUTE_TITLES.MANAGE_CUSTOMERS,
    permission: PERMISSIONS.ManageCustomers,
    group: NAVIGATION_GROUPS.Administrative.title,
    showInTableDropDown: true,
    assessments: true,
    exact: true,
  },
  manageCustomer: {
    path: "/manage-customers/:id",
    getComponent: () => import("pages/ManageCustomerPage/ManageCustomerPage"),
    title: ROUTE_TITLES.MANAGE_CUSTOMERS,
    permission: PERMISSIONS.ManageCustomers,
    exact: false,
    childRoutes: [
      {
        path: "/manage-customers/:id/unmatched",
        getComponent: () =>
          import(
            "pages/ManageCustomerPage/UnmatchedAdminPage/UnmatchedAdminPage"
          ),
        title: "Unmatched",
        permission: PERMISSIONS.ManageCustomers,
      },
    ],
  },
  thirdParty: {
    path: "/third-party/:id?",
    getComponent: () => import("pages/ThirdPartyPage"),
    title: "Third Party Details",
    permission: [
      PERMISSIONS.ThirdParty.Full,
      PERMISSIONS.ThirdParty.MyOrganization,
      PERMISSIONS.ThirdParty.Assigned,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
    exact: false,
    childRoutes: [
      {
        path: "/third-party/:id",
        getComponent: () => import("pages/ThirdPartyPage/CompanyProfile"),
        title: "Company Profile",
        permission: [
          PERMISSIONS.ThirdParty.Full,
          PERMISSIONS.ThirdParty.MyOrganization,
          PERMISSIONS.ThirdParty.Assigned,
        ],
      },
      {
        path: "/third-party/:id/people",
        getComponent: () => import("pages/ThirdPartyPage/People"),
        title: "People",
        permission: [
          PERMISSIONS.ThirdParty.People.ViewFull,
          PERMISSIONS.ThirdParty.People.ViewMyOrganization,
          PERMISSIONS.ThirdParty.People.ViewAssigned,
        ],
      },
      {
        path: "/third-party/:id/assessments",
        getComponent: () => import("pages/ThirdPartyPage/Assessments"),
        title: "Assessments",
        permission: [
          PERMISSIONS.Assessment.Full,
          PERMISSIONS.Assessment.MyOrganization,
          PERMISSIONS.Assessment.Assigned,
        ],
        hidden: true,
      },
      {
        path: "/third-party/:id/authorizations",
        getComponent: () => import("pages/ThirdPartyPage/Authorizations"),
        title: "Authorizations",
        permission: [PERMISSIONS.ThirdParty.ViewAuthorizations],
      },
    ],
  },
  assessment: {
    path: "/assessments/:id",
    getComponent: () => import("pages/AssessmentPage"),
    title: "Assessment Details",
    permission: [
      PERMISSIONS.Assessment.Full,
      PERMISSIONS.Assessment.MyOrganization,
      PERMISSIONS.Assessment.Assigned,
      PERMISSIONS.Assessment.Stage10.Assigned,
      PERMISSIONS.Assessment.Stage10.Full,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
    exact: false,
    childRoutes: [
      {
        id: "assessment-overview-tab",
        path: "/assessments/:id",
        getComponent: () => import("pages/AssessmentPage/Overview/Overview"),
        title: ROUTE_TITLES.OVERVIEW,
      },
      {
        id: "assessment-dashboard-tab",
        path: "/assessments/:id/dashboard",
        getComponent: () => import("pages/AssessmentPage/Dashboard/Dashboard"),
        title: "Dashboard",
        permission: PERMISSIONS.Assessment.ViewDashboard,
      },
      {
        id: "assessment-people-tab",
        path: "/assessments/:id/people",
        getComponent: () => import("pages/AssessmentPage/People/People"),
        title: "People",
        permission: PERMISSIONS.Assessment.People.View,
      },
      {
        id: "assessment-schedule-tab",
        path: "/assessments/:id/schedule",
        getComponent: () => import("pages/AssessmentPage/Schedule/Schedule"),
        title: "Schedule",
      },
      {
        id: "assessment-details-tab",
        path: "/assessments/:id/details",
        getComponent: () => import("pages/AssessmentPage/Details/Details"),
        title: "Details",
        permission: PERMISSIONS.Assessment.ViewDetails,
      },
      {
        id: "assessment-scoping-tab",
        path: "/assessments/:id/scoping",
        getComponent: () => import("pages/AssessmentPage/Scoping/Scoping"),
        title: "Scope Questions",
        permission: PERMISSIONS.Assessment.Scoping.View,
      },
      {
        id: "assessment-custom-scoping-tab",
        path: "/assessments/:id/bpqScoping",
        disabled: process.env.REACT_APP_CUSTOM_SCOPE === "false",
        getComponent: () =>
          import("pages/AssessmentPage/BpqScoping/BpqScoping"),
        title: "Custom Scope",
        permission: [
          PERMISSIONS.Assessment.Bpq.Scope.Full,
          PERMISSIONS.Assessment.Bpq.Scope.Assigned,
        ],
      },
      {
        id: "assessment-bpq-tab",
        path: "/assessments/:id/bpq",
        getComponent: () => import("pages/AssessmentPage/BPQ/BPQ"),
        title: "BPQ",
        permission: [
          PERMISSIONS.Assessment.Bpq.View,
          PERMISSIONS.Assessment.Bpq.ViewConditional,
        ],
      },
      {
        id: "assessment-artifacts-tab",
        path: "/assessments/:id/artifacts",
        getComponent: () => import("pages/AssessmentPage/Artifacts/Artifacts"),
        title: "Artifacts",
        permission: [PERMISSIONS.Assessment.Artifacts.View],
      },
      {
        id: "assessment-authorizations-tab",
        path: "/assessments/:id/authorizations",
        getComponent: () =>
          import("pages/AssessmentPage/Authorizations/Authorizations"),
        title: "Authorizations",
        permission: [
          PERMISSIONS.Assessment.Authorizations.View.Full,
          PERMISSIONS.Assessment.Authorizations.View.MyOrganization,
          PERMISSIONS.Assessment.Authorizations.View.Assigned,
        ],
      },
      {
        id: "assessment-product-download-tab",
        path: "/assessments/:id/product",
        getComponent: () =>
          import("pages/AssessmentPage/ProductDownload/ProductDownload"),
        title: "Product Download",
        permission: [PERMISSIONS.Assessment.Products.View],
      },
    ],
  },
  customTemplate: {
    path: "/custom-templates/:id",
    getComponent: () =>
      import("pages/TemplatesAndMappingPage/CustomTemplatePage"),
    title: ROUTE_TITLES.CUSTOM_TEMPLATE,
    permission: [
      PERMISSIONS.CustomTemplate.View.Full,
      PERMISSIONS.CustomTemplate.View.MyOrganization,
      PERMISSIONS.CustomTemplate.Edit.Full,
      PERMISSIONS.CustomTemplate.Edit.MyOrganization,
    ],
    exact: false,
  },
  tagCollection: {
    path: "/tag-collections/:id",
    getComponent: () =>
      import("pages/TemplatesAndMappingPage/TagCollectionPage"),
    title: ROUTE_TITLES.TAG_COLLECTION,
    exact: false,
  },
  bookOfWork: {
    path: "/book-of-work",
    getComponent: () => import("pages/GenerateBow/GenerateBow"),
    disabled: process.env.REACT_APP_FEATURE_BOOK_OF_WORK === "false",
    title: ROUTE_TITLES.BOOK_OF_WORK,
    exact: false,
    permission: [PERMISSIONS.ManageDemand],
  },
};

export const assessmentsRoutes = Object.values(routes)
  .filter((route) => route.assessments)
  .map((route) => route.path);

export const myListRoutes = Object.values(routes)
  .filter((route) => route.myList)
  .map((route) => route.path);

export default routes;
