import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  filters: null,
  loading: false,
  error: null,
  drawerType: null,
  customerThirdParties: null,
  customerThirdPartiesLoading: false,
  customerThirdPartiesError: null,
  customerThirdPartyRequests: {},
  customerThirdPartyRequestsLoading: {},
  customerThirdPartyRequestsError: {},
  customerThirdPartyLoadedRequest: null,
  modals: {
    connectRequestModal: {
      isOpen: false,
      isSaving: false,
      modalData: null,
    },
    disconnectRequestModal: {
      isOpen: false,
      isDisconnecting: false,
      modalData: null,
    },
  },
};

const slice = createSlice({
  name: "customerThirdPartiesPage",
  initialState,
  reducers: {
    loadMyThirdPartiesList: (state) => {
      state.data = null;
      state.filters = null;
      state.loading = true;
    },
    loadMyThirdPartiesListSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.items;
      state.filters = action.payload.filters;
      state.error = null;
    },
    loadMyThirdPartiesListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDrawerType: (state, action) => {
      state.drawerType = action.payload;
    },
    setWatchStatusForListItem: (state, action) => {
      if (state.data) {
        action.payload.assessmentIds.forEach((id) => {
          let item = state.data.find((x) => x.assessmentId === id);
          if (item) {
            item.isWatched = action.payload.isWatched;
          }
        });
      }
    },
    setPurchasedForListItem: (state, action) => {
      if (state.data) {
        action.payload.purchases.forEach((purchase) => {
          let item = state.data.find((x) => x.assessmentId === purchase.id);
          if (item) {
            item.isPurchased = true;
            item.isWatched = true;
            item.canPurchase = purchase.canPurchase;
            item.authorizationStatus = purchase.authorizationStatus;
            item.isProductAvailable = purchase.isProductAvailable;
            item.productId = purchase.productId;
          }
        });
      }
    },
    refresh: (state) => {
      if (state.data) {
        state.data = [...state.data];
      }
    },
    setDisconnectRequestModalOpen: (state, { payload }) => {
      state.modals.disconnectRequestModal.isOpen = payload.isOpen;
      state.modals.disconnectRequestModal.modalData = payload?.data || null;
    },
    setRequestDisconnecting: (state, { payload }) => {
      state.modals.disconnectRequestModal.isDisconnecting = payload;
    },
    disconnectRequest: (state) => {
      state.modals.disconnectRequestModal.isDisconnecting = true;
    },
    disconnectRequestSuccess: (state) => {
      state.modals.disconnectRequestModal.isDisconnecting = false;
      state.modals.disconnectRequestModal.isOpen = false;
    },
    disconnectRequestError: (state) => {
      state.modals.disconnectRequestModal.isDisconnecting = false;
    },
    loadCustomerThirdParties: (state) => {
      state.customerThirdParties = null;
      state.customerThirdPartiesLoading = true;
    },
    loadCustomerThirdPartiesSuccess: (state, action) => {
      state.customerThirdPartiesLoading = false;
      state.customerThirdParties = action.payload;
      state.customerThirdPartiesError = null;
    },
    loadCustomerThirdPartiesError: (state, action) => {
      state.customerThirdPartiesLoading = false;
      state.customerThirdPartiesError = action.payload;
    },
    loadCustomerThirdPartyRequests: (state, action) => {
      const { id } = action.payload;

      state.customerThirdPartyRequestsLoading[id] = true;
      state.customerThirdPartyLoadedRequest = null;
    },
    loadCustomerThirdPartyRequestsSuccess: (state, action) => {
      const { id, data } = action.payload;

      state.customerThirdPartyRequestsLoading[id] = false;
      state.customerThirdPartyRequests[id] = data;
      state.customerThirdPartyRequestsError[id] = null;
      state.customerThirdPartyLoadedRequest = { id, data };
    },
    loadCustomerThirdPartyRequestsError: (state, action) => {
      const { id, message } = action.payload;

      state.customerThirdPartyRequestsLoading[id] = false;
      state.customerThirdPartyRequestsError[id] = message;
    },
    refreshCustomerThirdParties: (state) => {
      if (state.customerThirdParties) {
        const customerThirdParties = state.customerThirdParties;
        state.customerThirdParties = customerThirdParties;
      }
    },
    setConnectRequestModalOpen: (state, { payload }) => {
      state.modals.connectRequestModal.isOpen = payload.isOpen;
      state.modals.connectRequestModal.modalData = payload?.data || null;
    },
    sendConnectRequest: (state) => {
      state.modals.connectRequestModal.isSaving = true;
    },
    sendConnectRequestCompleted: (state) => {
      state.modals.connectRequestModal.isSaving = false;
      state.modals.connectRequestModal.isOpen = false;
    },
  },
});

export const {
  loadMyThirdPartiesList,
  loadMyThirdPartiesListSuccess,
  loadMyThirdPartiesListError,
  setDrawerType,
  setWatchStatusForListItem,
  setPurchasedForListItem,
  refresh,
  setDisconnectRequestModalOpen,
  setRequestDisconnecting,
  disconnectRequest,
  disconnectRequestSuccess,
  disconnectRequestError,
  loadCustomerThirdParties,
  loadCustomerThirdPartiesSuccess,
  loadCustomerThirdPartiesError,
  loadCustomerThirdPartyRequests,
  loadCustomerThirdPartyRequestsSuccess,
  loadCustomerThirdPartyRequestsError,
  refreshCustomerThirdParties,
  setConnectRequestModalOpen,
  sendConnectRequest,
  sendConnectRequestCompleted,
} = slice.actions;

export default slice;
