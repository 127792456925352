import { createSlice } from "@reduxjs/toolkit";
import { escape } from "lodash";

import { NAMESPACE } from "./constants";
import { BPQ_FIELDS, REDACTION_FIELDS } from "app/constants";
import { isQuestionComplete } from "../components/utils/evaluateQuestionAnswer";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    document: null,
    normalizedQuestions: {},
    loading: false,
    error: null,
    rowToRefresh: null,
    filteredAndSortedQuestions: null,
    showQuestionsRequiringAction: false,
  },
  reducers: {
    loadBpqDocument: (state) => {
      state.document = null;
      state.normalizedQuestions = {};
      state.loading = true;
      state.error = null;
      state.filteredAndSortedQuestions = null;
    },
    loadBpqDocumentSuccess: (state, { payload }) => {
      const qcValidationResults = state.qcValidationResults;
      state.loading = false;
      state.error = null;
      payload.questions.forEach((question) => {
        state.normalizedQuestions[question.questionId] = question.answer;

        question.fields?.forEach((field) => {
          field.questionId = question.questionId;
          field.serialId = question.serialId;
          field.rules?.operands?.forEach((operand) => {
            operand.questionId = question.questionId;
          });
        });

        question.isComplete = isQuestionComplete(question);
        if (qcValidationResults) {
          question.qcValidationResult = qcValidationResults[question.serialId];
        }
      });
      payload.domains.forEach((domain) => {
        if (qcValidationResults) {
          domain.qcValidationResult = qcValidationResults[domain.label];
        }
      });
      state.document = payload;
    },
    loadBpqDocumentError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    loadQcValidationResults: (state) => {
      state.QcValidationResults = null;
      state.loadingQcValidationResults = true;
      state.QcValidationResultsError = null;
    },
    loadQcValidationResultsSuccess: (state, { payload }) => {
      const bpqDocument = state.document;
      state.loadingQcValidationResults = false;
      state.QcValidationResultsError = null;
      if (bpqDocument) {
        bpqDocument.questions.forEach((question) => {
          question.qcValidationResult = payload[question.serialId];
        });
        bpqDocument.domains.forEach((domain) => {
          domain.qcValidationResult = payload[domain.label];
        });

        state.document = bpqDocument;
      }

      state.qcValidationResults = payload;
    },
    loadQcValidationResultsError: (state, { payload }) => {
      state.loadingQcValidationResults = false;
      state.QcValidationResultsError = payload;
    },
    setAnswerToQuestion: (state, { payload }) => {
      payload.answers.forEach((payload) => {
        const targetQuestion = state?.document?.questions?.find(
          (question) => question.questionId === payload.questionId
        );
        const shouldBeRedacted = REDACTION_FIELDS.BPQ.includes(payload.key);
        if (!targetQuestion.answer) {
          const answer = {
            question: {
              [payload.key]: {
                value: payload.value,
                isRedactionLoading: shouldBeRedacted,
              },
            },
          };
          state.normalizedQuestions[payload.questionId] = answer;
          targetQuestion.answer = answer;
        } else {
          const answer = {
            ...targetQuestion.answer,
            question: {
              ...targetQuestion.answer.question,
              [payload.key]: {
                value: payload.value,
                isRedactionLoading: shouldBeRedacted,
              },
            },
          };
          state.normalizedQuestions[payload.questionId] = answer;
          targetQuestion.answer = answer;
        }

        targetQuestion.isComplete = isQuestionComplete(targetQuestion);
      });
    },
    setQuestionStatus: (state, { payload }) => {},
    setAttachmentsToQuestion: (state, { payload }) => {
      const targetQuestion = state?.document?.questions?.find(
        (question) => question.questionId === payload.answers[0].questionId
      );
      const evidence = {
        hasSensitiveArtifacts: {
          value: payload.answers[0][BPQ_FIELDS.evidence].hasSensitiveArtifacts,
        },
        artifacts: payload.answers[0][BPQ_FIELDS.evidence].artifacts,
      };

      if (!targetQuestion.answer) {
        targetQuestion.answer = {
          question: { evidence },
        };
      } else {
        targetQuestion.answer = {
          ...targetQuestion.answer,
          question: {
            ...targetQuestion.answer.question,
            evidence,
          },
        };
      }
      targetQuestion.isComplete = isQuestionComplete(targetQuestion);
      state.rowToRefresh = targetQuestion.questionId;
    },
    setObservationToQuestion: (state, { payload }) => {
      if (!state.document) {
        return;
      }

      const targetQuestion = state?.document?.questions?.find(
        (question) => question.serialId === payload.serialId
      );
      targetQuestion.answer = {
        ...targetQuestion.answer,
        observation: {
          ...targetQuestion.answer?.observation,
          type: {
            value: payload.comment.value,
          },
        },
      };
      state.rowToRefresh = targetQuestion.questionId;
    },
    setInternalNoteToQuestion: (state, { payload }) => {
      const targetQuestion = state?.document?.questions?.find(
        (question) => question.serialId === payload.serialId
      );
      let internalNoteValue = payload.comment.value.trim();
      internalNoteValue = internalNoteValue ? internalNoteValue : null;
      targetQuestion.answer = {
        ...targetQuestion.answer,
        review: {
          ...targetQuestion.answer?.review,
          internalNotes: {
            value: internalNoteValue,
          },
        },
      };
    },
    setSharedNoteToQuestion: (state, { payload }) => {
      const targetQuestion = state?.document?.questions?.find(
        (question) => question.serialId === payload.serialId
      );
      let sharedNoteValue = payload.comment.value.trim();
      sharedNoteValue = sharedNoteValue ? sharedNoteValue : null;
      targetQuestion.answer = {
        ...targetQuestion.answer,
        review: {
          ...targetQuestion.answer?.review,
          sharedNotes: {
            value: sharedNoteValue,
          },
        },
      };
    },

    setSmeNameToDomain: (state, { payload }) => {
      const targetDomain = state.document.domains.find(
        (domain) => domain.label === payload.answer.domainLabel
      );
      const oldName = targetDomain.smeName;
      targetDomain.smeName = payload.answer.value;

      if (oldName) {
        const questionsWithSummary = state.document.questions.filter(
          (question) =>
            question.questionDomain === payload.answer.domainLabel &&
            !!question?.answer?.review?.summary?.value
        );

        questionsWithSummary.forEach((question) => {
          replaceSmeInSummary(question, oldName, payload.answer.value);
        });

        state.rowToRefresh = questionsWithSummary.map(
          (question) => question.questionId
        );
      }
    },
    setSmeTitleToDomain: (state, { payload }) => {
      const targetDomain = state.document.domains.find(
        (domain) => domain.label === payload.answer.domainLabel
      );
      const oldTitle = targetDomain.smeTitle;
      targetDomain.smeTitle = payload.answer.value;

      if (oldTitle) {
        const questionsWithSummary = state.document.questions.filter(
          (question) =>
            question.questionDomain === payload.answer.domainLabel &&
            !!question?.answer?.review?.summary?.value
        );

        questionsWithSummary.forEach((question) => {
          replaceSmeInSummary(question, oldTitle, payload.answer.value);
        });

        state.rowToRefresh = questionsWithSummary.map(
          (question) => question.questionId
        );
      }
    },
    setQuestionState: (state, { payload }) => {
      payload.forEach((questionState) => {
        const targetQuestion = state?.document?.questions?.find(
          (question) => question.questionId === questionState.questionId
        );

        if (targetQuestion)
          targetQuestion[questionState.activeRuleType] =
            questionState[questionState.activeRuleType];
      });
    },
    setFieldState: (state, { payload }) => {
      payload.forEach((field) => {
        const targetQuestion = state?.document?.questions?.find(
          (x) => x.questionId === field.questionId
        );

        if (targetQuestion) {
          const targetField = targetQuestion?.fields?.find(
            (x) => x.name === field.name
          );
          targetField[field.activeRuleType] = field[field.activeRuleType];
          targetQuestion.isComplete = isQuestionComplete(targetQuestion);
        }
      });
    },
    setAnswerToCommentBuilderDrawerSuccess: (state, { payload }) => {
      const question = state.document.questions.find(
        (question) => question.questionId === payload.questionId
      );
      if (!question.answer) {
        question.answer = {
          review: { ...payload.answer.review },
          checklist: { ...payload.answer.checklist },
        };
      } else {
        question.answer = {
          ...question.answer,
          review: { ...payload.answer.review },
          checklist: { ...payload.answer.checklist },
        };
      }
    },
    updateTpResponseToQuestion: (state, { payload }) => {
      const question = state.document.questions.find(
        (question) => question.questionId === payload.questionId
      );
      const observation = question?.answer?.observation;
      if (observation) {
        question.answer.observation = {
          ...observation,
          type: { ...payload.responseObservation.type },
          thirdPartyResponse: {
            ...payload.responseObservation.thirdPartyResponse,
          },
          trusightResponse: { ...payload.responseObservation.trusightResponse },
        };
      }
    },
    updateRedactedQuestionAnswers: (state, { payload }) => {
      payload.forEach((redactedAnswer) => {
        const targetQuestion = state?.document?.questions?.find(
          (question) => question.questionId === redactedAnswer.questionId
        );
        if (targetQuestion.answer?.question?.[redactedAnswer.key]) {
          targetQuestion.answer.question[redactedAnswer.key] = {
            ...targetQuestion.answer.question[redactedAnswer.key],
            ...redactedAnswer.redactionResponse,
            isRedactionLoading: false,
          };
        }
        state.rowToRefresh = targetQuestion.questionId;
      });
    },
    setAnswerToPeerReviewDrawerSuccess: (state, { payload }) => {
      const question = state.document.questions.find(
        (question) => question.questionId === payload.questionId
      );
      if (!question.answer) {
        question.answer = {
          peerReviewFlag: { ...payload },
        };
      } else {
        question.answer = {
          ...question.answer,
          peerReviewFlag: { ...payload },
        };
      }
    },
    setQcFlagToDocumentState: (state, { payload }) => {
      const question = state.document.questions.find(
        (question) => question.questionId === payload.questionId
      );
      if (!question.answer) {
        question.answer = {
          qcFlag: { ...payload.qcFlag },
        };
      } else {
        question.answer = {
          ...question.answer,
          qcFlag: { ...payload.qcFlag },
        };
      }
    },
    setThematicFlagsToDocumentState: (state, { payload }) => {
      state.document = {
        ...state.document,
        thematicFlags: payload.thematicFlags,
      };
    },
    setRowToRefresh: (state, { payload }) => {
      state.rowToRefresh = payload.questionId;
    },
    setFilteredAndSortedQuestions: (state, { payload }) => {
      state.filteredAndSortedQuestions = payload;
    },
    // SignalR Slices
    updateQcValidationResult: (state, { payload }) => {
      const bpqDocument = state.document;
      const qcValidationResults = state.qcValidationResults;

      Object.keys(payload).forEach((serialId) => {
        if (bpqDocument)
          bpqDocument.questions.forEach((question) => {
            if (question.serialId === serialId) {
              question.qcValidationResult = payload[serialId];
              state.document = bpqDocument;
              state.rowToRefresh = question.questionId;
            }
          });

        if (qcValidationResults) {
          qcValidationResults[serialId] = payload[serialId];
          state.qcValidationResults = qcValidationResults;
        }
      });
    },
    setShowQuestionsRequiringAction: (state, { payload }) => {
      state.showQuestionsRequiringAction = payload;
    },
  },
});

const replaceSmeInSummary = (question, oldSmeValue, newValue) => {
  // Summary is generated by HandleBars which uses HtmlEncoding for strings so we use the same encoding for manual replace
  question.answer.review.summary.value =
    question.answer.review.summary.value.replaceAll(
      escape(oldSmeValue),
      escape(newValue)
    );
  if (question.answer.review.revisedSummary?.value) {
    question.answer.review.revisedSummary.value =
      question.answer.review.revisedSummary.value.replaceAll(
        oldSmeValue,
        newValue
      );
  }
};

export const {
  loadBpqDocument,
  loadBpqDocumentSuccess,
  loadBpqDocumentError,
  loadQcValidationResults,
  loadQcValidationResultsSuccess,
  loadQcValidationResultsError,
  setAnswerToQuestion,
  setQuestionStatus,
  setAttachmentsToQuestion,
  setQuestionState,
  setFieldState,
  setObservationToQuestion,
  setInternalNoteToQuestion,
  setSharedNoteToQuestion,
  setSmeNameToDomain,
  setSmeTitleToDomain,
  setAnswerToCommentBuilderDrawerSuccess,
  updateRedactedQuestionAnswers,
  setQcFlagToDocumentState,
  setRowToRefresh,
  setThematicFlagsToDocumentState,
  setAnswerToPeerReviewDrawerSuccess,
  updateTpResponseToQuestion,
  setFilteredAndSortedQuestions,
  setShowQuestionsRequiringAction,
  updateQcValidationResult,
} = slice.actions;

export default slice;
